import { DataInteractive as HeadlessDataInteractive } from "@headlessui/react";
import {
  Link as RemixLink,
  LinkProps as RemixLinkProps,
  useLocation,
} from "@remix-run/react";
import React, { forwardRef } from "react";

import type { To } from "react-router-dom";

export type LinkProps = {
  to?: string;
  href?: string;
  relative?: "path" | "route";
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "to">;

// Detect if we're in a Remix environment
function useIsRemixEnvironment(): boolean {
  try {
    useLocation();
    return true;
  } catch {
    return false;
  }
}

// Fallback for resolving paths when not in Remix
function resolvePath(to: string): string {
  try {
    return new URL(to, window.location.href).pathname;
  } catch (error) {
    console.warn("Invalid path provided:", to, error);
    return "/";
  }
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { to, href, relative, ...props },
  ref
) {
  const isRemixEnvironment = useIsRemixEnvironment();
  const resolvedTo = to ? resolvePath(to) : href;
  const isExternal =
    resolvedTo?.startsWith("http") || resolvedTo?.startsWith("mailto:");

  return (
    <HeadlessDataInteractive>
      {isRemixEnvironment && !isExternal && to ? (
        <RemixLink
          ref={ref}
          to={resolvedTo as To}
          relative={relative}
          {...(props as Omit<RemixLinkProps, "to">)}
        />
      ) : (
        <a
          ref={!isRemixEnvironment ? ref : undefined}
          href={resolvedTo as string}
          target={props.target || (isExternal ? "_blank" : undefined)}
          rel={isExternal ? "noopener noreferrer" : undefined}
          {...props}
        />
      )}
    </HeadlessDataInteractive>
  );
});
